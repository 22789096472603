
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { DonViService } from "@/core/services/DonVi.service";
import { CODE_REGEX } from "@/core/constants/const";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import Swal from "sweetalert2/dist/sweetalert2.js";
import BoPhanService from "@/core/services/BoPhan.service";
import { swalErrNotification, swalNotification } from '../../../../core/helpers/utils';

const customValidator = (_rule, value, fn) => {
  if (!value) {
    return fn(new Error("Bắt buộc phải nhập mã toa xe"));
  }

  setTimeout(() => {
    if (!CODE_REGEX.test(value)) {
      fn(
        new Error(
          "Mã bộ phận phải dúng định dạng, bao gồm chữ in hoa, số, dấu gạch ngang và gạch dưới"
        )
      );
    } else {
      fn();
    }
  }, 500);
};

export default defineComponent({
  name: "part-form",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // form elements
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);

    // local state
    const isCreating = ref(true);
    const departmentDetail = ref<{
      id: number;
      name: string;
      address: string;
    }>({
      id: -1,
      name: "",
      address: "",
    });

    // form state
    const form = reactive({
      name: "",
      code: "",
      address: "",
      description: "",
    });
    const rules = reactive({
      name: [
        {
          required: true,
          message: "Phải nhập tên của bộ phận",
          trigger: "blur",
        },
      ],
      code: [
        {
          required: true,
          message: "Phải nhập mã bộ phận",
          trigger: "blur",
        },
        {
          validator: customValidator,
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "Phải nhập địa chỉ của bộ phận",
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: "Phải nhập mô tả của bộ phận",
          trigger: "blur",
        },
      ],
    });

    onMounted(async () => {
      isCreating.value = route.name === "new-part-form";
      setCurrentPageTitle("Trang quản lý bộ phận");

      try {
        const {
          data: { data: departmentResponse },
        } = await DonViService.fetchCurrentDepartmentById(
          store.getters.currentUser.departmentId
        );
        departmentDetail.value.id = departmentResponse.id;
        departmentDetail.value.name = departmentResponse.ten_don_vi;
        departmentDetail.value.address = departmentResponse.dia_chi;
      } catch(e) {
        swalErrNotification(e, 'Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin đơn vị, mời bạn quay lại sau');
      }
      if (!isCreating.value) {
        try {
          const {
            data: {
              data: { ten_bo_phan, dia_chi, mo_ta, ma_bo_phan },
            },
          } = await BoPhanService.get(+route.params.id);
          form.name = ten_bo_phan;
          form.code = ma_bo_phan;
          form.address = dia_chi;
          form.description = mo_ta;
        } catch {
          Swal.fire({
            text: "Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin bộ phận , mời bạn quay lại sau",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Đã hiểu",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      }
    });

    const resetForm = () => {
      form.name = "";
      form.description = "";
      form.address = "";
      if (isCreating.value) {
        form.code = "";
      }
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          let fetchedId = route.params.id || -1;

          try {
            if (isCreating.value) {
              const {
                data: {
                  data: { id },
                },
              } = await BoPhanService.new(
                departmentDetail.value.id,
                form.name,
                form.code,
                form.address,
                form.description
              );
              fetchedId = id;
            } else {
              await BoPhanService.update(
                +route.params.id,
                departmentDetail.value.id,
                form.name,
                form.code,
                form.address,
                form.description
              );
            }
            swalNotification(`Bộ phận được ${
                isCreating.value ? "tạo mới" : "chỉnh sửa"
              } thành công`);
            router.push(`/quan-ly/bo-phan/chi-tiet/${fetchedId}`);
          } catch(e) {
            swalErrNotification(e, `Xin lỗi, không thể ${
                isCreating.value ? "tạo mới" : "chỉnh sửa"
              } bộ phận ko thành công, mời bạn thực hiện lại`);
          }
        } else {
          return false;
        }
      });
    };

    return {
      loading,
      formRef,
      isCreating,
      departmentDetail,
      form,
      rules,
      resetForm,
      onSubmit,
    };
  },
});
