import {ApiResponse, PartBaseResponse} from '../interfaces/ApiResponses';
import { apiServiceInstance } from "../helpers/utils";

export default class BoPhanService {
  static list(): Promise<{
    data: ApiResponse<{
      data: PartBaseResponse[];
      current_page: number;
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string | null;
      per_page: number;
      prev_page_url: string | null;
      to: number;
      total: number;
    }>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      "/api/v1/bo-phan/danh-sach"
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<PartBaseResponse>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bo-phan/chi-tiet/${id}`
    );
  }

  static new(
    departmentId: number,
    name: string,
    code: string,
    address: string,
    description: string
  ): Promise<{
    data: ApiResponse<PartBaseResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("boPhanInstance[id_don_vi]", departmentId.toString());
    bodyFormData.append("boPhanInstance[ten_bo_phan]", name);
    bodyFormData.append("boPhanInstance[ma_bo_phan]", code);
    bodyFormData.append("boPhanInstance[dia_chi]", address);
    bodyFormData.append("boPhanInstance[mo_ta]", description);
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/bo-phan/tao-moi",
      bodyFormData
    );
  }

  static update(
    id: number,
    departmentId: number,
    name: string,
    code: string,
    address: string,
    description: string
  ): Promise<{
    data: ApiResponse<PartBaseResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("boPhanInstance[id_don_vi]", departmentId.toString());
    bodyFormData.append("boPhanInstance[ten_bo_phan]", name);
    bodyFormData.append("boPhanInstance[ma_bo_phan]", code);
    bodyFormData.append("boPhanInstance[dia_chi]", address);
    bodyFormData.append("boPhanInstance[mo_ta]", description);
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/bo-phan/cap-nhat/${id}`,
      bodyFormData
    );
  }
}
