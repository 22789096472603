export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const CODE_REGEX = /^[A-Za-z0-9_-]*$/;

export const CONTRACT_CODE_REGEX = /^[A-Z0-9]*$/;

export const PRODUCT_CODE_NUMBER_VALIDATION_REGEX = /^[A-Z0-9-_]*$/;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const currentSelectableYear = [2023, 2024, 2025];

export const DUPPLICATE_SUPPLY_META_KEY = "Dữ liệu đã tồn tại";

export enum PRODUCT_SELECTING_PROGRESS {
  NOT_SELECTING = "NOT_SELECTING",
  SELECTING_CATEGORY = "SELECTING_CATEGORY",
  SELECTING_PRODUCT = "SELECTING_PRODUCT",
  DONE = "DONE",
}

export enum LOAI_DINH_MUC {
  NGOAI_DINH_MUC = 'NDM',
  DINH_MUC_CO_DINH = 'DMCD',
  TAT_CA_DINH_MUC = 'TCDM'
}

export const DEFAULT_PER_PAGE_KEY = 'DEFAULT_PER_PAGE_KEY';
